/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import { Typography, InputNumber, Button, Select, Tooltip, Tag, Icon } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { transparentize } from 'polished';
import Container from '../Container';
import { AddToCartSagaActionCreator, RemoveFromCartSagaActionCreator } from '../../redux/cart/cart.actions';
import css from './cartitem.module.scss';
import { CartItem, Option, ColorOption } from '../../lib/common-interfaces';
import { RootModel } from '../../redux/store';
import { itemDiscountTotal, pathTo } from '../../lib/utils';

interface CartItemProps {
  item: CartItem;
}

export const CartItemComponent = (props: CartItemProps) => {
  const { item } = props;
  const dispatch = useDispatch();
  const [quantity, updateQuantity] = useState<number>(0);
  const [optionSelected, updateOptionSelected] = useState<Option>();
  const [colorSelected, updateColorSelected] = useState<ColorOption>();
  const [hovered, updateHovered] = useState<string>();
  const [showTag, updateShowTag] = useState(false);
  const { loading, clickedId } = useSelector((state: RootModel) => ({
    loading: state.cart.status.loading,
    clickedId: state.cart.status.clickedId,
  }));
  const { t, i18n } = useTranslation();

  const isXS = useMediaQuery({ maxWidth: 480 });
  const isMD = useMediaQuery({ minWidth: 480, maxWidth: 768 });

  useEffect(() => {
    updateQuantity(item.quantity);
    if (item.option) updateOptionSelected(item.option);
    if (item.colorOption) updateColorSelected(item.colorOption);
  }, []);

  const updateQuantityInCart = (value?: number) => {
    if (value) {
      const diff = value - item.quantity;
      updateQuantity(value);
      dispatch(
        AddToCartSagaActionCreator({
          product: { product: item.product, quantity: diff, option: optionSelected, colorOption: colorSelected },
          showNotification: true,
        })
      );
    }
  };

  const changeOption = (option: Option) => {
    dispatch(
      RemoveFromCartSagaActionCreator({
        productId: item.product.idProduct as number,
        showNotification: false,
        option: optionSelected,
        colorOption: colorSelected,
      })
    );
    dispatch(
      AddToCartSagaActionCreator({
        product: { product: item.product, colorOption: colorSelected, quantity, option },
        showNotification: false,
      })
    );
  };

  const changeColor = (colorOption: ColorOption) => {
    dispatch(
      RemoveFromCartSagaActionCreator({
        productId: item.product.idProduct as number,
        showNotification: false,
        option: optionSelected,
        colorOption: colorSelected,
      })
    );
    dispatch(
      AddToCartSagaActionCreator({
        product: { product: item.product, quantity, option: optionSelected, colorOption },
        showNotification: false,
      })
    );
  };

  const removeProductFromCart = () => {
    dispatch(
      RemoveFromCartSagaActionCreator({ productId: item.product.idProduct as number, showNotification: true, option: optionSelected })
    );
  };

  const imageFixedPhoto = item && item.product && item.product.photo[0].fixed.src;

  return (
    <Container className={isXS || isMD ? css.mainContainerMobile : css.mainContainer}>
      <Container className={css.imgDescrContainter}>
        {item.product.photo && item.product.photo.length > 0 && item.product.photo[0] && (
          <Link to={pathTo(item.product.slug as string)}>
            <img
              src={isXS || isMD ? imageFixedPhoto : item.product.photo[0].cart.src}
              alt={item.product.title as string}
              style={isXS ? { maxWidth: '-webkit-fill-available' } : undefined}
            />
          </Link>
        )}
        <Container>
          <Typography.Title level={4}>{item.product.title}</Typography.Title>
          <Container className={css.priceContainer}>
            <Typography.Title level={4} className={item.discount && css.price}>
              &euro; {item.product.price}
            </Typography.Title>
            {item.discount && (
              <Typography.Title level={4} className={css.discount}>
                - &euro; {itemDiscountTotal(item)}
              </Typography.Title>
            )}
            {item.discount && <Typography.Title level={4}>= &euro; {item.product.price - itemDiscountTotal(item)}</Typography.Title>}
          </Container>
          {item.discount && <Typography.Text className={css.discount}>{item.discount.title}</Typography.Text>}

          {item.product.colorOption && (
            <>
              <Container className={css.optionContainer}>
                {item.product.colorOption.map(color => {
                  return (
                    <Tooltip title={color?.name} placement="top" arrowPointAtCenter key={color?.contentful_id as string}>
                      <Button
                        size={hovered === color?.contentful_id || colorSelected?.contentful_id === color?.contentful_id ? 'large' : 'small'}
                        shape="circle"
                        style={{
                          backgroundColor: transparentize(0.5, color?.hexCode as string),
                          border: `2px solid ${color?.hexCode as string}`,
                          margin: '7px',
                        }}
                        onMouseEnter={e => updateHovered(color?.contentful_id as string)}
                        onMouseLeave={e => updateHovered(undefined)}
                        onClick={e => {
                          changeColor({
                            contentful_id: color?.contentful_id as string,
                            name: color?.name as string,
                            hexCode: color?.hexCode as string,
                          });
                          updateColorSelected({
                            contentful_id: color?.contentful_id as string,
                            name: color?.name as string,
                            hexCode: color?.hexCode as string,
                          });
                        }}
                      >
                        {colorSelected?.contentful_id === color?.contentful_id && (
                          <Icon type="check" style={{ color: color?.hexCode as string }} />
                        )}
                      </Button>
                    </Tooltip>
                  );
                })}
              </Container>
              <Container className={css.warningContainter}>
                {item.product.colorOption && !colorSelected && showTag && (
                  <Tag color="red" className={css.tag}>
                    {t('shell:product.colorIsRequired')}
                  </Tag>
                )}
              </Container>
            </>
          )}
          {item.product.options && (
            <>
              <Container className={css.optionContainer}>
                {item.product.options.value?.map(value => {
                  return (
                    <Button
                      key={value as string}
                      className={css.optionSelect}
                      size={isXS || isMD ? 'large' : 'small'}
                      onClick={e => {
                        changeOption({ value: value as string, contentful_id: item.product.options?.contentful_id as string });
                        updateOptionSelected({ value: value as string, contentful_id: item.product.options?.contentful_id as string });
                      }}
                      color={optionSelected && value === optionSelected.value ? 'volcano' : undefined}
                      style={optionSelected && value === optionSelected.value ? { fontWeight: 600 } : undefined}
                    >
                      {value}
                    </Button>
                  );
                })}
              </Container>
              <Container className={css.warningContainter}>
                {item.product.options && !optionSelected && showTag && (
                  <Tag color="red" className={css.tag}>
                    {t('shell:product.optionIsRequired')}
                  </Tag>
                )}
              </Container>
            </>
          )}
        </Container>
      </Container>
      <Container className={isXS || isMD ? css.controlsContainerMobile : css.controlsContainer}>
        <Container className={isXS || isMD ? css.quantityAndDeleteContainerMobile : css.quantityAndDeleteContainer}>
          <InputNumber
            min={1}
            max={10}
            // defaultValue={item.quantity}
            value={quantity}
            size="large"
            className={css.quantityInput}
            onChange={value => updateQuantityInCart(value)}
            disabled={loading}
          />
          <Button type="default" shape="circle-outline" icon="close" onClick={removeProductFromCart} />
        </Container>
      </Container>
    </Container>
  );
};
