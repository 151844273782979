import * as React from 'react';
import { Link } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Typography, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { RootModel } from '../../redux/store';
import { ClearCartActionCreator } from '../../redux/cart/cart.actions';
import { SubmitOrderActionCreator } from '../../redux/order/order.actions';
import Container from '../Container';
import css from './checkoutconfirm.module.scss';
// import { defaultLanguage } from '../../constants/locales';
import { itemDiscountTotal, toDecimals, pathTo } from '../../lib/utils';

export interface CheckoutConfirmProps {
  visible: boolean;
  onCancel: () => void;
}

export const CheckoutConfirm = (props: CheckoutConfirmProps) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { visible, onCancel } = props;
  const { order } = useSelector((state: RootModel) => ({
    order: state.order.order,
  }));
  const { Title, Text } = Typography;
  const [okDisabled, setOkDisabled] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const onConfirm = () => {
    setOkDisabled(true);
    setConfirmLoading(true);
    console.log('onConfirm');
    if (order) {
      dispatch(SubmitOrderActionCreator(order));
      dispatch(ClearCartActionCreator());
    }
  };

  const afterCloseHandler = () => {
    setOkDisabled(false);
    setConfirmLoading(false);
  };

  const fields = {
    step1: ['firstName', 'lastName', 'email', 'country', 'city', 'address', 'postalCode', 'phone'],
    step2: ['method_text', 'total_price', 'delivery_time'],
    step3: ['name'],
  };

  const dataSubfields = {
    step1: 'user',
    step2: 'shippingMethod',
    step3: 'paymentMethod',
  };

  const renderFields = () => {
    return Object.entries(fields).map(([step, f]) => {
      return f.map(field => {
        return (
          <Container className={css.fieldContainer} key={field}>
            <Text type="secondary" className={css.label}>
              {t(`checkout:form.${step}.fields.${field}.label`)}
            </Text>
            <Text strong>{order && order[dataSubfields[step]] && order[dataSubfields[step]][field]}</Text>
          </Container>
        );
      });
    });
  };

  const renderCart = () => {
    if (order && order.products) {
      return order.products.map(cartitem => {
        return (
          <Container className={css.itemContainer} key={cartitem.product.idProduct as number}>
            <Container>
              {cartitem && cartitem.product.title && cartitem.product.photo.length && (
                <img src={cartitem.product.photo.length && cartitem.product.photo[0].thumbnail.src} alt={cartitem.product.title} />
              )}
            </Container>
            <Container className={css.innerContainer}>
              <Container className={css.titleOptionContainer}>
                <Link to={pathTo(cartitem.product.slug as string)}>
                  <Typography.Text className={css.itemText}>{cartitem.product.title}</Typography.Text>
                </Link>
                {cartitem.option && (
                  <Typography.Text
                    className={css.optionText}
                    type="secondary"
                  >{`${cartitem.product.options?.title}: ${cartitem.option.value}`}</Typography.Text>
                )}
                {cartitem.colorOption && (
                  <Typography.Text className={css.optionText} type="secondary">
                    {`${t('shell:cart.color')}: ${cartitem.colorOption.name}`}
                    <div className={css.colorSwatch} style={{ backgroundColor: cartitem.colorOption.hexCode }} />
                  </Typography.Text>
                )}
              </Container>
              <Container className={css.priceContainer}>
                <Typography.Text className={css.itemText}>&euro;{cartitem.product.price}</Typography.Text>
                <Typography.Text className={css.itemText}>&times;{cartitem.quantity}</Typography.Text>
                <Container className={css.itemTotal}>
                  {cartitem.discount && (
                    <Typography.Text className={css.discount}>- &euro; {itemDiscountTotal(cartitem) * cartitem.quantity}</Typography.Text>
                  )}
                  <Typography.Text>
                    = &euro;
                    {cartitem.quantity * cartitem.product.price - (cartitem.discount ? itemDiscountTotal(cartitem) * cartitem.quantity : 0)}
                  </Typography.Text>
                </Container>
              </Container>
              {cartitem.discount && <Typography.Text className={css.discountTitle}>{cartitem.discount.title}</Typography.Text>}
            </Container>
          </Container>
        );
      });
    }

    return null;
  };

  const evaluateSubTotal = (): number => {
    let total = 0;
    if (order && order.products) {
      order.products.map(item => {
        if (item.product.price) {
          total += item.product.price * item.quantity;
        }
      });
    }
    return total;
  };

  const evaluateDiscountTotal = (): number => {
    let total = 0;
    if (order && order.products) {
      order.products.map(item => {
        total += itemDiscountTotal(item) * item.quantity;
      });
    }
    return total;
  };

  const subTotal: number = toDecimals(evaluateSubTotal());
  const discount: number = toDecimals(evaluateDiscountTotal());
  const shipping: number = order ? toDecimals(order.shippingMethod?.total_price as number) : 0;
  const total: number = toDecimals(subTotal - discount + shipping);

  return (
    <>
      {order && (
        <Modal
          visible={visible}
          onCancel={onCancel}
          onOk={onConfirm}
          confirmLoading={confirmLoading}
          afterClose={afterCloseHandler}
          okText={t('checkout:confirmModal.okBtn.label')}
          cancelText={t('checkout:confirmModal.cancelBtn.label')}
          okButtonProps={{ disabled: okDisabled }}
        >
          <Title level={4}>{t('checkout:confirmModal.title')}</Title>
          <Container className={css.dataContainer}>
            {renderCart()}
            <Divider />
            {renderFields()}
            <Divider />
            {discount > 0 && (
              <Container className={css.totalsContainer}>
                <Text type="secondary" className={css.orderDiscount}>
                  {t('checkout:confirmModal.discount')}
                </Text>
                <Text type="secondary" className={css.orderDiscount}>
                  &euro;{discount}
                </Text>
              </Container>
            )}
            <Container className={css.totalsContainer}>
              <Text className={css.orderTotal}>{t('checkout:confirmModal.orderTotal')}</Text>
              <Text className={css.orderTotal}>&euro;{total}</Text>
            </Container>
          </Container>
        </Modal>
      )}
    </>
  );
};
