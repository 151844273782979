import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Card, Typography, Button, Icon, Input, notification, Spin } from 'antd';
import { ArgsProps } from 'antd/lib/notification';
import * as ISOCountries from 'i18n-iso-countries';
import { Currency } from '@altermeliora/payform-types';
import { RootModel } from '../../redux/store';
import Container from '../Container';
import css from './carttotals.module.scss';
import { CheckoutConfirm } from '../CheckoutConfirm/CheckoutConfirm';
import { ClearOrderActionCreator, SaveOrderActionCreator, clearSavedFieldsActionCreator } from '../../redux/order/order.actions';
import { getShippingMethodsActionCreator } from '../../redux/shipping/shipping.actions';
import { usePrevious } from '../../lib/hooks/usePrevious';
import { CheckPromocodeActionCreator } from '../../redux/cart/cart.actions';
import { itemDiscountTotal, toDecimals, pathTo } from '../../lib/utils';

// eslint-disable-next-line @typescript-eslint/no-var-requires
ISOCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
// eslint-disable-next-line @typescript-eslint/no-var-requires
ISOCountries.registerLocale(require('i18n-iso-countries/langs/ru.json'));

export interface CartTotalsProps {
  page: 'cart' | 'checkout';
}

export const CartTotals = (props: CartTotalsProps) => {
  const { page } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const isXS = useMediaQuery({ maxWidth: 480 });

  const applyButton = isXS ? <Icon type="search" /> : t('shell:cart.cartTotals.applyPromocode');

  const {
    loading,
    cart,
    order,
    shippingMethods,
    shippingSelected,
    shippingLoading,
    link,
    countryCode,
    manager,
    showManager,
    savedFields,
  } = useSelector((state: RootModel) => ({
    loading: state.cart.status.loading,
    cart: state.cart.cart,
    order: state.order.order,
    shippingMethods: state.shipping.methods,
    shippingSelected: state.shipping.selected,
    shippingLoading: state.shipping.status.loading,
    link: state.order.link,
    countryCode: state.geocode.countryCode,
    manager: state.order.manager,
    showManager: state.order.showManager,
    savedFields: state.order.savedFields,
  }));
  const [isConfirmVisible, updateConfirmVisible] = React.useState<boolean>(false);
  const [promocode, updatePromocode] = React.useState<string | null | undefined>();

  const prevCountryCode = usePrevious(countryCode);
  const prevCart = usePrevious(JSON.stringify(cart));

  React.useEffect(() => {
    if (order) dispatch(ClearOrderActionCreator());
    if (savedFields) dispatch(clearSavedFieldsActionCreator());
  }, []);

  const loadingStyle: React.CSSProperties = loading ? { filter: 'blur(4px)' } : {};

  const evaluateSubTotal = (): number => {
    let total = 0;
    cart.map(item => {
      if (item.product.price) {
        total += item.product.price * item.quantity;
      }
    });
    return total;
  };

  const evaluateDiscountTotal = (): number => {
    let total = 0;
    cart.map(item => {
      total += itemDiscountTotal(item) * item.quantity;
    });
    return total;
  };

  const subTotal: number = toDecimals(evaluateSubTotal());
  const discount: number = toDecimals(evaluateDiscountTotal());
  const shipping: number = shippingSelected ? toDecimals(shippingSelected?.total_price as number) : 0;
  const total: number = toDecimals(subTotal - discount + shipping);

  const getShareableLink = () => {
    if (savedFields) {
      dispatch(SaveOrderActionCreator(savedFields));
    }
  };

  const checkPromocode = () => {
    if (promocode) {
      dispatch(
        CheckPromocodeActionCreator({
          cart: cart.map(cartItem => {
            return {
              id: cartItem.product.contentful_id as string,
              quantity: cartItem.quantity,
            };
          }),
          code: promocode,
          currency: [Currency.euro],
        })
      );
    }
  };

  const shippingAvailable = () => {
    const countryName = countryCode ? ISOCountries.getName(countryCode, i18n.language) : undefined;
    if (shippingMethods && shippingMethods.length === 0) {
      if (countryCode === 'RU') {
        return t('checkout:shipping.notAvailableCountryAndCity');
      }
      return t('checkout:shipping.notAvailableCountry');
    }
    if (shippingMethods && shippingMethods.length > 0 && countryName) {
      const cheapShipping = shippingMethods.reduce((acc, curr) => {
        return acc.total_price > curr.total_price ? curr : acc;
      });
      const infoString = `${t('shell:cart.cartTotals.shippingCalc.shippingTo')} ${countryName} ${t(
        'shell:cart.cartTotals.shippingCalc.from'
      )}  \u20AC${cheapShipping.total_price.toFixed(2)}`;
      return infoString;
    }
  };

  React.useEffect(() => {
    if (countryCode && countryCode !== 'RU' && cart.length > 0 && (prevCountryCode !== countryCode || JSON.stringify(cart) !== prevCart)) {
      dispatch(getShippingMethodsActionCreator({ items: cart, country: countryCode, city: '', kladrId: '' }));
    }
  });

  return (
    <>
      <Card className={css.card} title={t('shell:cart.total')}>
        <Container className={css.totalsContainer}>
          <Typography.Text>{`${t('shell:cart.cartTotals.subtotal')}:`}</Typography.Text>
          <Typography.Text style={loadingStyle}>&euro; {subTotal.toFixed(2)}</Typography.Text>
        </Container>

        <Container className={css.totalsContainer}>
          <Typography.Text>{`${t('shell:cart.cartTotals.promocode')}:`}</Typography.Text>
          <Input onChange={e => updatePromocode(e.target.value)} style={{ maxWidth: '100px' }} />
          <Button type="default" disabled={!promocode || promocode.length === 0} onClick={e => checkPromocode()}>
            {loading ? <Icon type="loading" /> : applyButton}
          </Button>
        </Container>

        {discount > 0 && (
          <Container className={css.totalsContainer}>
            <Typography.Text>{`${t('shell:cart.cartTotals.discount')}:`}</Typography.Text>
            <Typography.Text style={loadingStyle}>&euro; {discount.toFixed(2)}</Typography.Text>
          </Container>
        )}
        {shippingSelected && (
          <Container className={css.totalsContainer}>
            <Typography.Text style={{ maxWidth: '20ch' }}>{`${t('shell:cart.cartTotals.shipping')}(${
              shippingSelected.method_text
            }):`}</Typography.Text>
            <Typography.Text style={loadingStyle}>&euro; {shippingSelected.total_price.toFixed(2)}</Typography.Text>
          </Container>
        )}
        <Container className={css.totalsContainer}>
          <Typography.Text strong>{`${t('shell:cart.cartTotals.total')}:`}</Typography.Text>
          <Typography.Text strong style={loadingStyle}>
            &euro; {total.toFixed(2)}
          </Typography.Text>
        </Container>
        {page === 'cart' && (
          <Spin spinning={shippingLoading} indicator={<Icon type="loading" style={{ fontSize: '24px' }} />}>
            <Container className={css.shippingContainer}>
              <Typography.Text>{shippingAvailable()}</Typography.Text>
              {shippingMethods && (
                <Typography.Text className={css.moreinfo}>{t('shell:cart.cartTotals.shippingCalc.moreinfo')}</Typography.Text>
              )}
            </Container>
          </Spin>
        )}
        <Container className={css.totalsContainer}>
          <Button
            type="primary"
            size="large"
            block
            onClick={e => (page === 'cart' ? navigate(pathTo('checkout')) : updateConfirmVisible(true))}
            disabled={loading || cart.length === 0 || total < 0 || (page === 'checkout' && !order)}
          >
            {t('shell:cart.checkout')}
          </Button>
        </Container>
        {page === 'checkout' && manager && showManager && (
          <Container className={css.totalsContainer}>
            <Button
              type="ghost"
              size="small"
              onClick={e => getShareableLink()}
              disabled={
                loading ||
                cart.length === 0 ||
                total <= 0 ||
                (page === 'checkout' && !savedFields) ||
                (page === 'checkout' && !savedFields?.user?.firstName && !savedFields?.user?.lastName)
              }
            >
              {loading ? <Icon type="loading" /> : <Icon type="link" style={{ color: '#d9d9d9' }} />}
              {t('shell:cart.share')}
            </Button>
            {link && (
              <Input
                size="small"
                value={link}
                style={{ marginLeft: '5px' }}
                addonAfter={
                  <Icon
                    type="copy"
                    onClick={() => {
                      const notificationOptions: ArgsProps = {
                        placement: 'topRight',
                        message: t('shell:order.messages.link'),
                      };
                      navigator.clipboard.writeText(link).then(() => notification.info(notificationOptions));
                    }}
                  />
                }
              />
            )}
          </Container>
        )}
      </Card>
      <CheckoutConfirm visible={isConfirmVisible} onCancel={() => updateConfirmVisible(false)} />
    </>
  );
};
