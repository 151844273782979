import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import { Row, Col, Card, Typography, Button } from 'antd';
import Page from '../components/Page/Page';
import IndexLayout from '../layouts';
import css from './cart.module.scss';
import { CartItemComponent } from '../components/CartItem/CartItem';
import { CartTotals } from '../components/CartTotals/CartTotals';
import { RootModel } from '../redux/store';
import { CartItem } from '../lib/common-interfaces';

const CartPage = () => {
  const { t } = useTranslation();
  const { cart } = useSelector((state: RootModel) => ({
    loading: state.cart.status.loading,
    clickedId: state.cart.status.clickedId,
    cart: state.cart.cart,
  }));

  const rowStyle: React.CSSProperties = {
    maxWidth: '1140px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '40px',
    marginBottom: '40px',
  };

  return (
    <IndexLayout>
      <Page>
        <Row style={rowStyle}>
          <Typography.Title level={1} style={{ textAlign: 'center' }}>
            {t('shell:cart.cart')}
          </Typography.Title>
        </Row>
        <Row style={rowStyle}>
          <Col lg={16} md={24}>
            <Card className={css.card}>
              {cart.length > 0 && cart.map((item: CartItem) => <CartItemComponent item={item} key={item.product.idProduct as number} />)}
              {cart.length === 0 && (
                <>
                  <Typography.Title level={2}>{t('shell:cart.empty')}</Typography.Title>
                  <Link to="/">
                    <Button type="primary" size="large">
                      {t('shell:cart.back')}
                    </Button>
                  </Link>
                </>
              )}
            </Card>
          </Col>
          <Col lg={8} md={24}>
            <CartTotals page="cart" />
          </Col>
        </Row>
      </Page>
    </IndexLayout>
  );
};

export default CartPage;
